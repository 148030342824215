import React, { useEffect, useReducer } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader, Loading } from "lib"
import { GoalSettingDefinitionSetClient, GoalSettingClient } from "@api"
import { SubmitForm } from "@components/configuartion/GoalSet"
import { translate } from "@action"

const GoalSettingSetConfiguration = ({ location }) => {
  const { state = null } = location
  const isCreate = !state?.id
  const initialState = {
    isLoading: false,
    data: null,
    options: {
      goal: [],
    },
  }

  const reducer = (state, { type, payload }) => {
    switch (type) {
      case "initPage":
        return {
          ...state,
          data: {
            ...payload,
            guideLineZH: translate(
              payload.goalSettingGuideLine,
              process.env.ZH_CODE
            ),
            guideLineEN: translate(
              payload.goalSettingGuideLine,
              process.env.EN_CODE
            ),
            goalSettingDefinitionSetGoalSettingDefinition:
              payload.goalSettingDefinitions
                .sort((a, b) => a.order - b.order)
                .map(item => {
                  return item.goalSettingDefinitionId
                }),
          },
          isLoading: false,
        }
      case "isLoading":
        return {
          ...state,
          isLoading: payload,
        }
      case "options":
        return { ...state, options: payload }

      default:
        break
    }
  }

  const [{ isLoading, options, data }, dispatch] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    const fetchData = async id => {
      dispatch({ type: "isLoading", payload: true })
      const client = new GoalSettingDefinitionSetClient()
      const result = await client.get2(id).catch(err => {
        console.log(err)
        return null
      })

      dispatch({ type: "initPage", payload: result })
    }

    const fetchOptions = async () => {
      const client = new GoalSettingClient()
      const options = await client.get(0, 0, "name", true)

      dispatch({
        type: "options",
        payload: {
          goal: options.content.goalSettingGroup,
        },
      })
    }

    if (!isCreate) {
      fetchData(state.id)
    }
    fetchOptions()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MainLayout>
      <Seo title="Goal Setting Set Configuration" />
      <PageHeader title={isCreate ? "Create Goal Setting Set" : data?.name} />
      {!isLoading ? (
        <SubmitForm id={state?.id} options={options} data={data} />
      ) : (
        <Loading />
      )}
    </MainLayout>
  )
}

export default GoalSettingSetConfiguration
