import React, { useState, useEffect } from "react"
import { Form, Row, Col, Button, Input, Checkbox } from "antd"
import GuideLine from "./GuideLine"
import GoalSetting from "./GoalSetting"
import { GoalSettingDefinitionSetClient } from "@api"
import { showError, showSuccess } from "@action"
import { navigate, Link } from "gatsby"
import { DeleteButton } from "lib"

const SubmitForm = ({ id, options, data }) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async values => {
    try {
      setIsLoading(true)
      const newValue = {
        ...values,
        goalSettingGuideLine: {
          translations: [
            {
              cultureInfoId: process.env.EN_CODE,
              message: values.guideLineEN,
            },
            {
              cultureInfoId: process.env.ZH_CODE,
              message: values.guideLineZH,
            },
          ],
        },
      }
      const client = new GoalSettingDefinitionSetClient()
      if (!id) {
        await client.create(newValue)
        showSuccess("Create Success!")
      } else {
        await client.update(id, {
          id: id,
          ...newValue,
        })
        showSuccess("Edit Success!")
      }
      navigate("/admin_panel/goalSettingSet")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }
  const handleDelete = async () => {
    try {
      setIsLoading(true)
      const client = new GoalSettingDefinitionSetClient()
      await client.delete(id)
      showSuccess("Delete Success")
      navigate("/admin_panel/goalSettingSet")
    } catch (err) {
      showError(err)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!!data) form.setFieldsValue(data)
  }, [data])

  const colProps = { span: 8 }
  return (
    <Form form={form} onFinish={onFinish}>
      <Row gutter={[12, 12]}>
        <Col {...colProps}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please input code",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            label="Enable"
            name="isEnabled"
            valuePropName="checked"
            rules={[{ required: true }]}
          >
            <Checkbox />
          </Form.Item>
        </Col>

        <Col span={24}>
          <GuideLine />
        </Col>
        <Col span={24}>
          <GoalSetting options={options.goal} />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center">
        {data?.isCanDelete && (
          <Col>
            <DeleteButton
              disabled={isLoading}
              onClick={() => {
                handleDelete()
              }}
            />
          </Col>
        )}
        <Col>
          <Button
            loading={isLoading}
            type="danger"
            shape="round"
            onClick={() => {
              form.submit()
            }}
          >
            {!!id ? "Save" : "Add"}
          </Button>
        </Col>

        <Col>
          <Link to="/admin_panel/GoalSettingSet/">
            <Button shape="round" disabled={isLoading}>
              Cancel
            </Button>
          </Link>
        </Col>
      </Row>
    </Form>
  )
}

export default SubmitForm
