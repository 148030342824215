import React, { useRef } from "react"
import { Form, Card, Divider } from "antd"
import JoditEditor from "jodit-react"
import { editorConfig } from "@action"

const GuideLine = () => {
  const ZhEditor = useRef(null)
  const EnEditor = useRef(null)
  return typeof window !== "undefined" ? (
    <Card>
      <Divider orientation="left">GuideLine</Divider>
      <Form.Item name="guideLineEN">
        <JoditEditor ref={ZhEditor} config={editorConfig} tabIndex={0} />
      </Form.Item>

      <Divider orientation="left">指導方針</Divider>
      <Form.Item name="guideLineZH">
        <JoditEditor ref={EnEditor} config={editorConfig} tabIndex={0} />
      </Form.Item>
    </Card>
  ) : null
}

export default GuideLine
